import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Header } from './Header/Header';
import Footer from './Footer/Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <div style={{position: 'relative', minHeight: '100vh', paddingBottom: '115.2px'}} >
        <Header />
        <div>
          {this.props.children}
            </div>
            <Footer />
      </div>
    );
  }
}
