import Home from "./components/Home/Home";
import { About } from "./components/About";
import Gallery from "./components/Gallery";
import { Quotes } from "./components/Quotes";
import Contact from "./components/Contact";
import Projects from "./components/Projects";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/work',
        element: <Gallery />
    },
    {
        path: '/bltd',
        element: <Projects />
    },
    {
        path: '/contact',
        element: <Contact />
    }
];

export default AppRoutes;
