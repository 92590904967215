import imgSrc1 from '../assets/images/Trauma_Infographic.jpg';
import imgSrc2 from '../assets/images/individual.jpg';
import imgSrc3 from '../assets/images/Limitations.jpg';
import imgSrc5 from '../assets/images/Community.jpg';
import imgSrc6 from '../assets/images/resistance.jpg';
import imgSrc7 from '../assets/images/oppression.jpg';
import imgSrc8 from '../assets/images/black_ppl_identity.jpg';
import imgSrc9 from '../assets/images/IMG_1365.jpg';
import imgSrc10 from '../assets/images/IMG_1491.PNG';
import imgCommunityCare from '../assets/images/Community care.jpg';
import imgCultivate from '../assets/images/Cultivate.jpg';
import imgJoyAndOppresions from '../assets/images/JoyAndOppression.jpg';
import imgCommunityRoots from '../assets/images/Community Roots.jpg';
import imgFreedomDreaming from '../assets/images/freedom dreaming.jpg';
import imgAAAM1 from '../assets/images/AAAM-1.jpg';
import imgAAAM2 from '../assets/images/AAAM-2.jpg';
import imgAAAM3 from '../assets/images/AAAM-3.jpg';
import imgAAAM4 from '../assets/images/AAAM-4.jpg';
import imgAAAM5 from '../assets/images/AAAM-5.jpg';
import bamboo from '../assets/images/Bamboo.PNG';
import deao from '../assets/images/DEAO.PNG';
import seamangoLogo from '../assets/images/Logo1.gif';
import apiCaucus from '../assets/images/ApiCaucus.PNG'
import gatorConnect from '../assets/images/GatorConnect.png'
import babyBond from '../assets/images/BabyBond.JPG';
import babyBondReport from '../assets/Baby Bonds Report.pdf';
const gotv = require.context('../assets/images/GOTV');
const palestine = require.context('../assets/images/Palestine');
const polishthepolls = require.context('../assets/images/Polish the Polls');

function usePhotoSources() {
    const gotvSrcs = gotv.keys().map(image => gotv(image));
    const palestineSrcs = palestine.keys().map(image => palestine(image));
    const polishthepollsSrcs = polishthepolls.keys().map(image => polishthepolls(image));

    function getGOTVDescription(index) {
        switch (index) {
            case 0:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *third-person view of person holding ballot* Recieve your Ballot';
            case 1:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *third-person view of person holding ballot* Recieve your Ballot (Vietnamese translation)';
            case 2:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *third-person view of person filling in ballot* Make your Vote';
            case 3:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *third-person view of person filling in ballot* Make your Vote (Vietnamese translation)';
            case 4:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *woman and child outdoors* Drop off your Ballot';
            case 5:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *woman and child outdoors* Drop off your Ballot (Vietnamese translation)';
            case 6:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *dragon pointing to phone* Check your Ballot Status';
            case 7:
                return 'GOTV design for the CA Healthy Nail Salon Collaborative to increase voter engagement in the Vietnamese Community: *dragon pointing to phone* Check your Ballot Status (Vietnamese translation)';
        }
    }

    function getPalestineDescription(index) {
        switch (index) {
            case 0:
                return 'Inspired by the resilience of women of color who have been victims of U.S. imperialism and capitalism, this design was created to honor the women who are living under systems of oppression across the world. Flowers bloom and persist even through harsh conditions, thus using the countries national flower to represent women\'s beauty and resilience through such systems of violence. I understand this graphic does not represent all countries, however it will always be Free Palestine, Free Congo, Free Sudan, Free Myanmar, Free Uyghurs, Free Yemen, Free Ethiopa Tigray and all countries living under the hands of settler colonialism.';
            case 1:
                return 'Inspired by the resilience of women of color who have been victims of U.S. imperialism and capitalism, this design was created to honor the women who are living under systems of oppression across the world. Flowers bloom and persist even through harsh conditions, thus using the countries national flower to represent women\'s beauty and resilience through such systems of violence. I understand this graphic does not represent all countries, however it will always be Free Palestine, Free Congo, Free Sudan, Free Myanmar, Free Uyghurs, Free Yemen, Free Ethiopa Tigray and all countries living under the hands of settler colonialism. This quote is from Audrey Lorde, "Without community there is no liberation."'
            case 2:
                return 'Inspired by the resilience of women of color who have been victims of U.S. imperialism and capitalism, this design was created to honor the women who are living under systems of oppression across the world. Flowers bloom and persist even through harsh conditions, thus using the countries national flower to represent women\'s beauty and resilience through such systems of violence. I understand this graphic does not represent all countries, however it will always be Free Palestine, Free Congo, Free Sudan, Free Myanmar, Free Uyghurs, Free Yemen, Free Ethiopa Tigray and all countries living under the hands of settler colonialism. This quote is from Audrey Lorde, "I am not free while any woman is unfree, even when her shackles are different than my own."'
            case 3:
                return 'Inspired by the resilience of women of color who have been victims of U.S. imperialism and capitalism, this design was created to honor the women who are living under systems of oppression across the world. Flowers bloom and persist even through harsh conditions, thus using the countries national flower to represent women\'s beauty and resilience through such systems of violence. I understand this graphic does not represent all countries, however it will always be Free Palestine, Free Congo, Free Sudan, Free Myanmar, Free Uyghurs, Free Yemen, Free Ethiopa Tigray and all countries living under the hands of settler colonialism. This quote is from Audrey Lorde, "Revolution is not a one-time event."';
        }
    }

    function getPolishthePollsDescription(index) {
        switch (index) {
            case 0:
                return 'GOTV Design, Polish the Polls, designed for CA Healthy Nail Salon Collaborative. Image shows family of five, with parents and their children, showing the simplicity of voting.';
            case 1:
                return 'GOTV Design, Polish the Polls, designed for CA Healthy Nail Salon Collaborative. Image shows family of five, with parents and their children, showing the simplicity of voting. (Vietnamese translation)';

        }
    }

    return [
        {
            title: 'DEAO',
            src: deao,
            description: 'DEAO',
            backgroundSize: 'contain',
            backgroundColor: 'white',
            category: 'logo'
        },
        {
            title: 'SEAsian Mango',
            src: seamangoLogo,
            description: 'SEAsian Mango',
            backgroundSize: 'contain',
            backgroundColor: 'white',
            backgroundPositionX: 'center',
            backgroundPositionY: '30px',
            category: 'logo'
        },
        {
            title: 'A Gift of Resilience',
            src: bamboo,
            description: 'A Gift of Resilience',
            backgroundSize: 'cover',
            category: 'personal'
        },
        {
            title: 'Liberation for Women of the World',
            src: palestineSrcs.map((src, i) => ({
                src: src,
                title: 'Liberation for Women of the World',
                description: getPalestineDescription(i)
            })),
            category: 'personal'
        },
        {
            title: 'Get out the Vote: Polish the Polls',
            src: polishthepollsSrcs.map((src, i) => ({
                src: src,
                title: 'Get out the Vote: Polish the Polls',
                description: getPolishthePollsDescription(i)
            })),
            category: 'commission'
        },
        {
            title: 'Utilize Wealth to Fund California Baby Bonds',
            src: babyBond,
            backgroundSize: 'cover',
            description: `<a href="${babyBondReport}" target="_blank">View the Full Report</a>`,
            category: 'commission'
        },
        {
            title: 'Get Out the Vote for the Healthy Nail Salon Collaborative',
            src: gotvSrcs.map((src, i) => ({
                src: src,
                title: 'Get Out the Vote for the Healthy Nail Salon Collaborative' + i,
                description: getGOTVDescription(i)
            })),
            category: 'commission'
        },
        {
            title: 'Black Resistance and Joy Photovoice Exhibit Infographics from Students ',
            src: [
                {
                    src: imgCommunityCare,
                    title: 'Community Care',
                    description: 'To care for our community, we first care for ourselves. We take the time to learn what we need to be supported and happy. This is then a reflection of what we can do for others. Once we are taken care of, we can support our communities. We care for our communities through action, through mentorship, through protection.'
                },
                {
                    src: imgCultivate,
                    title: 'Cultivating Safety and Joy',
                    description: 'Safety and joy are the two things that are constantly being taken away from the Black community. I am constantly looking out, moving silently, trying to avoid detection. Hoping that they don\'t try to teach me a lesson, comfortability breeds contempt, always on the offense.'
                },
                {
                    src: imgJoyAndOppresions,
                    title: 'Joy and Oppression',
                    description: 'Joy is found through internalized harmony that is developed in the face of oppression. We express our joy and power by choosing what we enjoy and how we broadcast our joy...'
                },
                {
                    src: imgCommunityRoots,
                    title: 'Community Roots',
                    description: 'Our community roots are deep and continue to grow. They provide us with a strong foundation that sustains us. So we give back to where we came from, to uplift the places that shaped us. Our roots are our joy, our roots are belonging, our roots are essential for life.'
                },
                {
                    src: imgFreedomDreaming,
                    title: 'Freedom Dreaming',
                    description: 'The freedom from the shackles of slavery. Today, we dream of the freedom to be welcomed and accepted as we are. Every day as Black students on campus we must advocate for our community to promote a new environment on campus. We are the future. We are the change we want to see in order to be free.'
                },
            ],
            category: 'commission'
        },
        {
            src: imgSrc10,
            backgroundSize: 'cover',
            title: 'Blooming through the Darkness: Cultivating Peace through Adversity',
            description: 'This was design for an event flyer.',
            category: 'commission'
        },
        {
            title: 'Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis',
            src: [
                {
                    src: imgAAAM1,
                    title: 'Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis',
                    description: 'These artworks attempt to visually depict the themes found in recent publications: Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis. Please note that the artworks represent only one way to depict the themes found in the paper and are designed to be used as a visual aid in understanding the lived experiences of Asian American men\'s socialization process. The Digital Equity & Anti-Oppression Lab owns the copyright; these images are not to be used for any commercial purposes.'
                },
                {
                    src: imgAAAM2,
                    title: 'Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis',
                    description: 'These artworks attempt to visually depict the themes found in recent publications: Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis. Please note that the artworks represent only one way to depict the themes found in the paper and are designed to be used as a visual aid in understanding the lived experiences of Asian American men\'s socialization process. The Digital Equity & Anti-Oppression Lab owns the copyright; these images are not to be used for any commercial purposes.'
                },
                {
                    src: imgAAAM3,
                    title: 'Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis',
                    description: 'These artworks attempt to visually depict the themes found in recent publications: Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis. Please note that the artworks represent only one way to depict the themes found in the paper and are designed to be used as a visual aid in understanding the lived experiences of Asian American men\'s socialization process. The Digital Equity & Anti-Oppression Lab owns the copyright; these images are not to be used for any commercial purposes.'
                },
                {
                    src: imgAAAM4,
                    title: 'Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis',
                    description: 'These artworks attempt to visually depict the themes found in recent publications: Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis. Please note that the artworks represent only one way to depict the themes found in the paper and are designed to be used as a visual aid in understanding the lived experiences of Asian American men\'s socialization process. The Digital Equity & Anti-Oppression Lab owns the copyright; these images are not to be used for any commercial purposes.'
                },
                {
                    src: imgAAAM5,
                    title: 'Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis',
                    description: 'These artworks attempt to visually depict the themes found in recent publications: Asian American Men\'s Gendered Racial Socialization and Fragmented Masculinity: An Interpretive Phenomenological Analysis. Please note that the artworks represent only one way to depict the themes found in the paper and are designed to be used as a visual aid in understanding the lived experiences of Asian American men\'s socialization process. The Digital Equity & Anti-Oppression Lab owns the copyright; these images are not to be used for any commercial purposes.'
                }
            ],
            category: 'commission'
        },
        {
            src: imgSrc9,
            title: 'The Interactional Process of The Self, The Supervisor, and the System',
            description: 'This infographic illustrates this interactional process that highlights how the self, supervisor, and the system impact MSW student\'s well-being.',
            backgroundSize: 'cover',
            category: 'commission'
        },
        {
            title: 'The Individual and Institutional Pathway of Trauma (Re)Production in Schools',
            src: [
                {
                    src: imgSrc1,
                    title: 'The Individual and Institutional Pathway of Trauma (Re)Production in Schools',
                    description: 'Infographics that explain the conceptual framework for the individual and institutional factors that (re)produce trauma in schools'
                },
                {
                    src: imgSrc2,
                    title: 'The Individual and Institutional Pathway of Trauma (Re)Production in Schools',
                    description: 'For the individual. On the left, supportive mechanisms: experiences, coping skills, relationship with students, professional experiences, relationship with staff. On the right, limitations: personal experiences with trauma, biases, distance from students, length of time spent in schools.'
                },
                {
                    src: imgSrc3,
                    title: 'The Individual and Institutional Pathway of Trauma (Re)Production in Schools',
                    description: 'Institutional level: Limitations are state laws, informal trainings, mandatory trainings, inadequate responses from schools, (un)available institutional support. On the right for supportive: on-site resources, school policies, in-service training, pre-service training, role awareness or acceptance.'
                }
            ],
            category: 'commission'
        },
        {
            title: 'Gator Connect',
            src: gatorConnect,
            description: 'Gator Connect',
            backgroundSize: '70%',
            backgroundPositionX: 'center',
            backgroundPositionY: '38px',
            backgroundColor: 'white',
            category: 'logo'
        },
        {
            title: 'API Caucus',
            src: apiCaucus,
            description: 'API Caucus',
            backgroundColor: 'white',
            backgroundPositionX: '56px',
            backgroundSize: 'contain',
            category: 'logo'
        },
        {
            title: 'Black Lives Matter all the Time: A Photovoice Project with Undergraduate Black Women at Predominantly White Institutions',
            src: [
                {
                    src: imgSrc5,
                    title: 'Community',
                    description: 'In the face of being Black, community is vital for growing, resistance and healing in predominantly white institutions.'
                },
                {
                    src: imgSrc6,
                    title: 'Resistance',
                    description: 'Resistance is woven into the fabric of what it means to be Black in America. As we persist in spaces that were not built for us, our very existence is an act of resistance.'
                },
                {
                    src: imgSrc7,
                    title: 'Oppression',
                    description: 'Oppression feels like constant suffocation. The struggle to stay afloat amid daily encounters with emotional, psychological, and physical violence. Yet, we as Black students thrive in spaces that were not built for us to succeed.'
                },
                {
                    src: imgSrc8,
                    title: 'Identity',
                    description: 'As Black people, there can be a disconnect between how we perceive ourselves vs. the way we are perceived. However, identity is not one-dimensional; it is a celebration of multiple aspects, including heritage, experience, and perspective.'
                }
            ],
            category: 'commission'
        }
    ];
}

export default usePhotoSources;