import React from 'react';
import useWindowSize from '../hooks/useWindowSize';

const Contact = (props) => {
    const windowSize = useWindowSize();

    return (
        <div className={(windowSize.width >= 768 ? 'pt-4 pb-4 container' : '')} >
            <div className={'col bg-white rounded-3' + (windowSize.width >= 768 ? ' p-5' : ' ps-1 pe-1 pt-5 pb-5')}>
                <div className="text-center">
                    <div className="mb-4">
                        <h2>How can I help?</h2>
                        <span>For further inquiries, you can contact me at <a href="mailto:seasianmango@gmail.com">seasianmango@gmail.com</a></span>
                    </div>
                    <div>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScQpfCVrB9tEnnc5iLo7eluP-SCKgTHSD27NKZl0B1gt9XMew/viewform?embedded=true" width="100%" height="1800" frameBorder="0" marginHeight="0" marginWidth="0">Loading�</iframe>
                    </div>
                    {/*<div className="mb-4">*/}
                    {/*    <div><h3>or</h3></div>*/}
                    {/*    <div>Book a time slot with me:</div>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1d9q3ZJDKXDmuoeqNVHoBwcY2paV4LTelX4HGiO7NzED9wv2jGo--4tvYsr8-53ItyPKmRR4NE?gv=true" style={{ border: '1px solid #E6E6E6', borderRadius: '8px', backgroundColor: 'white', paddingTop: (windowSize.width >= 768 ? '0px' : '20px') }} width="100%" height={(windowSize.width >= 768 ? 750 : 1310)} frameBorder="0"></iframe>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default Contact;

//export class Contact extends Component {
//    constructor(props) {
//        super(props);
//        this.state = {
//            fullName: '',
//            email: '',
//            message: '',
//            isMessageSent: false
//        };

//        this.onFullNameChange = this.onFullNameChange.bind(this);
//        this.onEmailChange = this.onEmailChange.bind(this);
//        this.onMessageChange = this.onMessageChange.bind(this);
//        this.sendMessage = this.sendMessage.bind(this);
//    }


//    onFullNameChange(e) {
//        this.setState({ fullName: e.target.value });
//    }

//    onEmailChange(e) {
//        this.setState({ email: e.target.value });
//    }

//    onMessageChange(e) {
//        this.setState({ message: e.target.value });
//    }

//    sendMessage() {
//        fetch('api/contact/get', {
//            method: 'post',
//            headers: {
//                'Content-Type': 'application/json'
//            },
//            body: JSON.stringify({
//                fulName: this.state.fullName,
//                email: this.state.email,
//                message: this.state.message
//            })
//        }).then(response => {
//            //if (response.ok)
//            //    this.setState({ isMessageSent: true });
//            if (response.ok)
//                response.json()
//        })
//            .then(data => {
//                var test = data;
//            });
//    }

//    render() {
//        return (
//            <div className="mt-5 mb-5 row justify-content-center">
//                {/*<div className="col-10 col-md-8 col-lg-6 bg-white p-5 rounded-3">*/}
//                {/*    <div style={{ display: 'none' }} >*/}
//                {/*        {this.state.isMessageSent &&*/}
//                {/*            <div className="text-center">*/}
//                {/*                <h4>Message Sent!</h4>*/}
//                {/*                Your message has been sent to Mary!*/}
//                {/*            </div>*/}
//                {/*        }*/}

//                {/*        {!this.state.isMessageSent &&*/}
//                {/*            <>*/}
//                {/*                <div className="text-center mb-4">*/}
//                {/*                    <h2 className="mb-0">How can I help?</h2>*/}
//                {/*                    <small>Send me a message and I can help you out shortly.</small>*/}
//                {/*                </div>*/}
//                {/*                <div className="form-group mb-3">*/}
//                {/*                    <label for="txtFullName">Full Name</label>*/}
//                {/*                    <input type="text" className="form-control" id="txtFullName" placeholder="Enter full name" value={this.state.fullName} onChange={this.onFullNameChange} />*/}
//                {/*                </div>*/}
//                {/*                <div className="form-group mb-3">*/}
//                {/*                    <label for="txtEmail">Email</label>*/}
//                {/*                    <input type="email" className="form-control" id="txtEmail" placeholder="Enter email" value={this.state.email} onChange={this.onEmailChange} />*/}
//                {/*                </div>*/}
//                {/*                <div className="form-group mb-3">*/}
//                {/*                    <label for="txtMessage">Message</label>*/}
//                {/*                    <textarea className="form-control" id="txtMessage" placeholder="Enter message" value={this.state.message} onChange={this.onMessageChange} />*/}
//                {/*                </div>*/}
//                {/*                <div>*/}
//                {/*                    <button className="btn btn-primary w-100" onClick={this.sendMessage}>Send</button>*/}
//                {/*                </div>*/}
//                {/*            </>*/}
//                {/*        }*/}
//                {/*    </div>*/}
//                {/*</div>*/}
//                <div className="col bg-white p-5 rounded-3">
//                    <div className="text-center">
//                        <div className="mb-4">
//                            <h2>How can I help?</h2>
//                            <span>For further inquries, you can contact me at <a href="mailto:seasianmango@gmail.com" className="fw-bold">seasianmango@gmail.com</a></span>
//                        </div>
//                        <div className="mb-4">
//                            <div><h3>or</h3></div>
//                            <div>Book a time slot with me:</div>
//                        </div>
//                        <div>
//                            <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1d9q3ZJDKXDmuoeqNVHoBwcY2paV4LTelX4HGiO7NzED9wv2jGo--4tvYsr8-53ItyPKmRR4NE?gv=true" style={{ border: '1px solid #E6E6E6', borderRadius: '8px', backgroundColor: 'white' }} width="100%" height="750" frameBorder="0"></iframe>
//                        </div>
//                    </div>
//                </div>
//            </div>
//        );
//    }
//}
