import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import './Footer.scss';

const Footer = () => {
    return (
        <div id="footer">
            <div className="mb-2">
                <a href="https://www.instagram.com/seasianmango" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
                </a>
            </div>
            Copyright @ 2023 Seasian Mango. All rights reserved.
        </div>
    )
}

export default Footer;