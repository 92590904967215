import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './Slider.scss';

const Slider = (props) => {
    const [pause, setPause] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const items = ['slider1', 'slider2', 'slider3']

    useEffect(() => {
        setTimeout(() => {
            if (!pause)
                nextSlide();
        }, 5000);
    });

    let nextSlide = () => {
        const slides = document.querySelectorAll(".slider-item");

        var nextSlideIndex = currentSlideIndex === slides.length - 1 ? 0 : currentSlideIndex + 1;

        slides[currentSlideIndex].classList.add('slider-item-start');
        slides[nextSlideIndex].classList.add('slider-item-next');

        setTimeout(() => {
            slides[currentSlideIndex].classList.remove('slider-item-start');
            slides[currentSlideIndex].classList.remove('active');
            slides[nextSlideIndex].classList.remove('slider-item-next');
            slides[nextSlideIndex].classList.add('active');
            setCurrentSlideIndex(nextSlideIndex);
        }, 600);
    }

    let prevSlide = () => {
        const slides = document.querySelectorAll(".slider-item");

        var prevSlideIndex = currentSlideIndex === 0 ? slides.length - 1 : currentSlideIndex - 1;

        slides[currentSlideIndex].classList.add('slider-item-end');
        slides[prevSlideIndex].classList.add('slider-item-prev');

        setTimeout(() => {
            slides[currentSlideIndex].classList.remove('slider-item-end');
            slides[currentSlideIndex].classList.remove('active');
            slides[prevSlideIndex].classList.remove('slider-item-prev');
            slides[prevSlideIndex].classList.add('active');
            setCurrentSlideIndex(prevSlideIndex);
        }, 600);
    }

    return (
        <div className="slider" onMouseEnter={() => setPause(true)} onMouseLeave={() => setPause(false)}>
            {props.children}
            <div className="container position-relative align-items-center">
                <button className="slider-navigation-button slider-prev-button" onClick={prevSlide}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button className="slider-navigation-button slider-next-button" onClick={nextSlide}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        </div>
    )
}

export default Slider;