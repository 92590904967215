import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './ImageSlider.scss';

const ImageSlider = (props) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const maxSlide = props.datasource.length - 1;

    useEffect(() => {
        const slides = document.querySelectorAll(".slide");

        slides.forEach((slide, i) => {
            slide.style.transform = `translateX(${100 * (i - currentSlideIndex)}%)`;
        });
    });

    let prevSlide = () => {
        const slides = document.querySelectorAll(".slide");
        var slideIndex = currentSlideIndex;

        if (currentSlideIndex === 0)
            setCurrentSlideIndex(maxSlide);
        else {
            slideIndex--;
            setCurrentSlideIndex(slideIndex);
        }

        slides.forEach((slide, i) => {
            slide.style.transform = `translateX(${100 * (i - slideIndex)}%)`;
        });
    }

    let nextSlide = () => {
        const slides = document.querySelectorAll(".slide");
        var slideIndex = currentSlideIndex;

        if (currentSlideIndex === maxSlide)
            setCurrentSlideIndex(0);
        else {
            slideIndex++;
            setCurrentSlideIndex(slideIndex);
        }

        slides.forEach((slide, i) => {
            slide.style.transform = `translateX(${100 * (i - slideIndex)}%)`;
        });
    }

    return (
        <div style={{ maxWidth: '1920px' }}>
            <div className="image-slider">
                {props.datasource.length > 1 &&
                    <>
                        <button className="prev-button nav-button" onClick={prevSlide}><i class="bi bi-arrow-left-circle"></i></button>
                        <button className="next-button nav-button" onClick={nextSlide}><i class="bi bi-arrow-right-circle"></i></button>
                    </>
                }

                {props.datasource.map((item, i) => {
                    return (
                        <div key={uuidv4()} className="slide" style={{ transform: `translateX(${i * 100}%)` }} >
                            <div className="row">
                                <div className="col-md-7 col-sm-12 img-container">
                                    <img src={item.src} />
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <h3>{item.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ImageSlider;