import React from 'react';
import PhotoGallery from '../components/PhotoGallery/PhotoGallery';
import usePhotoSources from '../hooks/usePhotoSources';

const Gallery = () => {
    const photos = usePhotoSources();
   
    return (
        <div className="pt-4 pb-4 container">
            <div className="text-center p-4 mb-4" style={{ backgroundColor: 'rgba(69, 93, 71, 0.75)', borderRadius: '6px' }} >
                <h2>Portfolio</h2>
                <div className="small">My mission is to create a meaningful and resonating message through artistic imagery for the client and the community it was meant to serve. The process of creating my designs are a collaborative effort and requires constant communication with my clients to ensure their visions comes true. I have designed title covers for research projects and publications, messaging for the community, flyers, art gallery pieces, and visuals for academic thesis for my clients. It is a pleasure to have the opportunity to work with individuals and organizations of shared values, with foundations rooted in liberation and social economic justice. Special shoutout to my clients who gave me their trust in creating something special! All contents of this gallery was designed and digitally drawn by yours truly. If you are interested in collaborating or have inquiries, please contact me at <a href="mailto:seasianmango@gmail.com">seasianmango@gmail.com</a>.</div>
            </div>
            <div>
                <div className="fsize-24">Commission</div>
                <PhotoGallery datasource={photos.filter(x => x.category === 'commission')} />
            </div>
            {/*<div>*/}
            {/*    <div className="fsize-24">Design</div>*/}
            {/*    <PhotoGallery datasource={photos.filter(x => x.category === 'design')} />*/}
            {/*</div>*/}
            <div>
                <div className="fsize-24">Logos</div>
                <PhotoGallery datasource={photos.filter(x => x.category === 'logo')} />
            </div>
            {/*<div>*/}
            {/*    <div className="fsize-24">Photography</div>*/}
            {/*    <PhotoGallery datasource={photos.filter(x => x.category === 'photography')} />*/}
            {/*</div>*/}
            <div>
                <div className="fsize-24">Personal</div>
                <PhotoGallery datasource={photos.filter(x => x.category === 'personal')} />
            </div>
        </div>
    );
}

export default Gallery;

//commission
//design
//logo
//photography
//personali