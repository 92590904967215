import React, { Component, useRef } from 'react';
import imgSrc from '../assets/images/Me.JPEG';

export class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ windowWidth: window.innerWidth });
        });
    }

    render() {
        return (
            <div className="pt-4 pb-4 container">
                <div id="about" className="row p-4 mb-5" style={{ backgroundColor: '#f1e5c9', borderRadius: '6px' }}>
                    {this.state.windowWidth >= 1200 &&
                        <>
                            <div className="col-5">
                                <img src={imgSrc} className="w-100 mb-3" style={{ borderRadius: '6px' }} />
                                <div>
                                    <div className="me-3"><b>Noun:</b> <span className="italic">listener and visual storyteller</span></div>
                                    <div className="me-3"><b>Adjective:</b> <span className="italic">compassionate, caring, innovative</span></div>
                                    <div className="me-3"><b>Verb:</b> <span className="italic">connecting communities with creative storytelling and visual imagery</span></div>
                                </div>
                            </div>
                            <div className="col">
                                <h2 className="mb-4">About Me</h2>
                                <div className="mb-4">
                                    Greetings! Mary Nguyen (she/her/Chị/Em) is a proud daughter of Vietnamese refugees, a student of life, and a self-taught artist in graphic design, photography, and videography. Although born and raised in Orange County, she received her dual master's degree in Social Welfare and Public Policy from UC Los Angeles and has centered her work on social and economic justice. She feels most alive in spaces where deep connections are created with folks in her community through shared values, experiences, music, artistry, food, and creativity. Wanting to make a mixed-media artistic and creative archive of lived experiences and educational content, she hopes that it will empower communities to diver deeper and obtain the knowledge that isn’t always found in traditional education settings.
                                </div>
                                <div>
                                    <div className="mb-1">What else Mary likes (in no particular order):</div>
                                    <div className="mb-1">🍃 Neo 🐶 , Tigerlily 😺, Lychee 😺, and Soju 😺</div>
                                    <div className="mb-1">🍃 Anime (One Piece is her favorite)</div>
                                    <div className="mb-1">🍃 Finding new music to listen to</div>
                                    <div className="mb-1">🍃 Playing piano or guitar</div>
                                    <div className="mb-1">🍃 Traveling</div>
                                    <div className="mb-1">🍃 Dancing </div>
                                </div>
                            </div>
                        </>
                    }

                    {this.state.windowWidth < 1200 &&
                        <div className="col p-4" style={{ backgroundColor: '#f1e5c9', borderRadius: '6px' }} >
                            <div className="text-center">
                                <img src={imgSrc} className="mb-4" style={{ borderRadius: '6px', width: (this.state.windowWidth < 768 ? '100%' : '500px') }} />
                                <h2 className="mb-4">About Me</h2>
                            </div>
                            <div className="mb-3">
                                <div className="me-3"><b>Noun:</b> <span className="italic">listener and visual storyteller</span></div>
                                <div className="me-3"><b>Adjective:</b> <span className="italic">compassionate, caring, innovative</span></div>
                                <div className="me-3"><b>Verb:</b> <span className="italic">connecting communities with creative storytelling and visual imagery</span></div>
                            </div>
                            <div className="mb-4">
                                Greetings! Mary Nguyen (she/her/Chị/Em) is a proud daughter of Vietnamese refugees, a student of life, and a self-taught artist in graphic design, photography, and videography. Although born and raised in Orange County, she received her dual master's degree in Social Welfare and Public Policy from UC Los Angeles and has centered her work on social and economic justice. She feels most alive in spaces where deep connections are created with folks in her community through shared values, experiences, music, artistry, food, and creativity. Wanting to make a mixed-media artistic and creative archive of lived experiences and educational content, she hopes that it will empower communities to diver deeper and obtain the knowledge that isn’t always found in traditional education settings.
                            </div>
                            <div>
                                <div className="mb-1">What else Mary likes (in no particular order):</div>
                                <div className="mb-1">🍃 Neo 🐶 , Tigerlily 😺, Lychee 😺, and Soju 😺</div>
                                <div className="mb-1">🍃 Anime (One Piece is her favorite)</div>
                                <div className="mb-1">🍃 Finding new music to listen to</div>
                                <div className="mb-1">🍃 Playing piano or guitar</div>
                                <div className="mb-1">🍃 Traveling</div>
                                <div className="mb-1">🍃 Dancing </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
