import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import Slider from '../../Slider/Slider';
import SliderItem from '../../Slider/SliderItem';
import { v4 as uuidv4 } from 'uuid';
import './TestimonialCarousel.scss';

const TestimonialCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = [
        {
            title: 'From Danielle, India, Jala, Yesenia, and Young (MPP Capstone Team):',
            detail: `We chose to work with Mary because of her talent and her commitment to community and justice. Mary not only captured our vision to fully fund baby bonds in California, she far exceeded our expectations in regards to her care, patience, and diligence. As an alum of Luskin's Master in Public Policy program, Mary understood the Applied Policy Project, including existing constraints and the importance of including all impacted stakeholders. It took our team and clients a few drafts to really think hard and narrow in on what we wanted for the front cover graphic. Mary prompted us with questions and ideas to cultivate a design that excited everyone and most importantly, our client. Even in the final hours ahead of deadline, Mary was up into the wee hours, committed to making the final modifications we requested. She was integral to our final report and felt like a true teammate. Thank you, Mary!`
        },
        {
            title: 'From Melanie Sonsteng-Person (Social Worker, Educator, Activist):',
            detail: `I am a researcher and had been wanting to make my data more accessible for the communities and schools that I work with. I reached out to Mary to see if she would be able to translate my research results to an infographic. Mary did an excellent job in the first project that I began to write her into all of my grants and have had colleagues do the same. Since my first time working with her in 2021 she has worked on three of my other grants resulting in several infographics and program logos. Her work is so beautiful and compelling. I have included it in conference presentations, on my website, and posted it to social media. I also get her pieces printed to give as gifts to research participants and community members. They always love seeing their words and experiences in such a beautiful and accessible format. I now have 5 of her pieces framed and hanging in my office. It makes me feel so proud of the work I have done and allows me to engage a wider audience. Research doesn't need to be boring!`
        }
    ];

    const slides1 = items.map(item => {
        return (
            <CarouselItem key={uuidv4()} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
                <div className="container" style={{ paddingLeft: '60px', paddingRight: '60px' }} >
                    <div className="item-detail">{item.detail}</div>
                </div>
            </CarouselItem>
        );
    });

    const slides = items.map((item, index) => {
        return (
            //<CarouselItem key={uuidv4()} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
            //    <div className="container" style={{ paddingLeft: '60px', paddingRight: '60px' }} >
            //        <div className="item-detail">{item.detail}</div>
            //    </div>
            //</CarouselItem>

            <SliderItem key={uuidv4()} active={index === 0 ? true : false}>
                <div className="container" style={{ paddingLeft: '60px', paddingRight: '60px' }} >
                    <div className="item-detail">{item.detail}</div>
                </div>
            </SliderItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    return (
        <div id="testimonial">
            <div className="title mb-3">What People Are Saying</div>
            <Slider>
                {slides}
            </Slider>

            {/*<Carousel className="testimonial-carousel" activeIndex={activeIndex} next={next} previous={previous} ride="carousel" dark fade>*/}
            {/*    {slides}*/}
            {/*    <CarouselControl*/}
            {/*        direction="prev"*/}
            {/*        directionText="Previous"*/}
            {/*        onClickHandler={previous}*/}
            {/*    />*/}
            {/*    <CarouselControl*/}
            {/*        direction="next"*/}
            {/*        directionText="Next"*/}
            {/*        onClickHandler={next}*/}
            {/*    />*/}
            {/*</Carousel>*/}
        </div>
    )
}

export default TestimonialCarousel;