import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import useWindowSize from '../../hooks/useWindowSize';
import seamangoLogo from '../../assets/images/Logo1.gif';

import './Header.scss';

export function Header(props) {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [collapsed, setCollapsed] = useState(true);
    function navigateToContact() {
        navigate('/contact');
    }

    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    return (
        <header id="header">
            {windowSize.width >= 768 &&
                <div className="container">
                    <div id="banner">
                        <Link to="/home"><img src={seamangoLogo} /></Link>
                    </div>
                    <div id="nav" className="d-flex align-items-center">
                        <div>
                            <Link to="/about" className="me-2">About</Link>
                            <Link to="/work" className="me-2">Portfolio</Link>
                            <Link to="/bltd">BTLD</Link>
                        </div>
                        <div className="banner-quote">FRESH QUALITY ALL YEAR ROUND</div>
                        <div className="pb-2 ms-auto">
                            <Link to="/contact" className="btn btn-primary">Let's Chat</Link>
                        </div>
                    </div>
                </div>
            }

            {windowSize.width < 768 &&
                <Navbar className="navbar-mobile navbar-expand-md navbar-toggleable-md ng-white border-bottom box-shadow" container dark>
                    <div className="col-2">
                        <NavbarBrand tag={Link} to="/"><img className="website-logo-mobile" src={seamangoLogo} /></NavbarBrand>
                    </div>
                    <div className="banner-quote flex-grow-1 text-center">FRESH QUALITY ALL YEAR ROUND</div>
                        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <Link tag={Link} className="text-light" to="/about">About</Link>
                            </NavItem>
                            <NavItem>
                                <Link tag={Link} className="text-light" to="/work">Portfolio</Link>
                            </NavItem>
                            <NavItem>
                                <Link tag={Link} className="text-light" to="/bltd">BTLD</Link>
                            </NavItem>
                            <NavItem>
                                <Link tag={Link} className="text-light" to="/contact">Let's Chat</Link>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            }
        </header>
    )
}