import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from '../../hooks/useWindowSize';
import usePhotoSources from '../../hooks/usePhotoSources';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import TestimonialCarousel from './TestimonialCarousel/TestimonialCarousel';
import './Home.scss';

const Home = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [photos, setPhotos] = useState(usePhotoSources().slice(0, 6));
    const [posts, setPosts] = useState([]);
    const windowSize = useWindowSize();

    //componentDidMount() {
    //    window.addEventListener('resize', () => {
    //        this.setState({ windowWidth: window.innerWidth });
    //    });
    //}

    //    sendMessage() {
    //        fetch('api/contact/get', {
    //            method: 'post',
    //            headers: {
    //                'Content-Type': 'application/json'
    //            },
    //            body: JSON.stringify({
    //                fulName: this.state.fullName,
    //                email: this.state.email,
    //                message: this.state.message
    //            })
    //        }).then(response => {
    //            //if (response.ok)
    //            //    this.setState({ isMessageSent: true });
    //            if (response.ok)
    //                response.json()
    //        })
    //            .then(data => {
    //                var test = data;
    //            });
    //    }

    useEffect(() => {
        getPublishedPosts();
    }, [])

    let getPublishedPosts = () => {
        fetch('api/substack/getPublishedPosts')
            .then(response => response.json())
            .then(data => {
                setPosts(data);
            });
    }

    return (
        <div id="home" className="pt-4">
            <div>
                <div id="substack-feed-embed" className="container pt-3 pb-3">
                    <PhotoGallery datasource={photos} />
                </div>
                <div id="latestPost">
                    <div className="container">
                        <div className="latest-post-header-title">Latest Post</div>
                        <div className="row">
                            {posts.slice(0, 3).map((post) => {
                                return (
                                    <div key={uuidv4()} className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 position-relative d-flex">
                                        <a href={post.url} className="post" target="_blank">
                                            <div className="post-image-container">
                                                <img className="post-image" src={post.imageUrl} />
                                            </div>
                                            <div style={{ height: windowSize.width >= 768 ? '127px' : 'auto' }}>
                                                <div className="post-date">{new Date(post.postDate).toLocaleDateString()}</div>
                                                <div className="post-title">{post.title}</div>
                                                <div className="post-subtitle">{post.subtitle}</div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                 <TestimonialCarousel />
            </div>
            <HashLink to="#top" style={{
                position: 'fixed', right: '15px', height: '50px', width: '50px', bottom: '15px', backgroundColor: '#628b68', borderRadius: '50%', padding: '5px', boxShadow: '0px 0px 6px 0px grey', display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-up" viewBox="0 0 16 16" width="25" height="25" fill="#444545">
                    <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"></path>
                </svg>
            </HashLink>
        </div>
    );
}

export default Home;